<template>
  <div class="home">
    <div class="slider">
      <Slider />
    </div>


    <div class="about">
      <div class="container">
        <h2>فكرتنا</h2>
        <p>
          المساهمة في تقليل حالات الوفاة التي تحدث بسبب عدم توفر المتبرعيـن بالدم في المكان والزمـان المناسبيـن وذلك
          بتكوين قاعدة بيانات تفاعلية تمكن من الوصول للمتبرعين بأسرع و أيسر أحدث الطرق بالإستفادة من تكنلوجيا الاتصالات
          والمعلومات في مشروع يوحد الجهود بين جميع العاملين مجـال
        </p>
      </div>
    </div>

    <div class="situations">
      <div class="container">
        <h2>احدث الحالات</h2>
        <div class="row">
          <div class="col-md-4">
            <Card :class="important">
              <p slot="title"> فصيلة الدم O-ve </p>
              <p>
                حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع محمد نجيب مع شارع 61
                نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
              </p>
            </Card>
          </div>
          <div class="col-md-4">
            <Card>
              <p slot="title"> فصيلة الدم O-ve </p>
              <p>
                حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع محمد نجيب مع شارع 61
                نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
              </p>
            </Card>
          </div>
          <div class="col-md-4">
            <Card :class="important">
              <p slot="title"> فصيلة الدم O-ve </p>
              <p>
                حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع محمد نجيب مع شارع 61
                نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
              </p>
            </Card>
          </div>
          <div class="col-md-4">
            <Card>
              <p slot="title"> فصيلة الدم O-ve </p>
              <p>
                حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع محمد نجيب مع شارع 61
                نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
              </p>
            </Card>
          </div>
          <div class="col-md-4">
            <Card :class="important">
              <p slot="title"> فصيلة الدم O-ve </p>
              <p>
                حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع محمد نجيب مع شارع 61
                نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
              </p>
            </Card>
          </div>
          <div class="col-md-4">
            <Card >
              <p slot="title"> فصيلة الدم O-ve </p>
              <p>
                حوجة عاجلة لمتبرعين بالدم فصيلة O-ve لعملية ولادة بمستشفى سعد أبو العلا تقاطع شارع محمد نجيب مع شارع 61
                نثق في سرعة استجابتكم للتبرع يرجى التواصل على الرقم 0999088040
              </p>
            </Card>
          </div>
        </div>
      </div>
    </div>

    <div class="blog" dir="rtl">
      <h2>احدث المقالات</h2>
      <div class="container">
        <div class="row">
          <div class="col-md-4">
            <Card>
              <div style="text-align:center">
                <img style="width:100%" src="../assets/blog1.jpg">
                <hr>
                <h3>الدم هو البجمعنا</h3>
              </div>
            </Card>
          </div>
          
        </div>

      </div>
    </div>

    <div class="register">
      <div class="container">
        <h2>كن معنا</h2>
        <div class="row">
          <div class="col col-md-12">
            <Card>
              <div style="text-align:center">
                <router-link to="/donor"><h3>تسجيل متبرع</h3></router-link>
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>


  </div>
</template>

<script>
  // @ is an alias to /src
  import Slider from "@/components/SliderComponent.vue";
  export default {
    name: "Home",
    components: {
      Slider,
    },
    data() {
      return {
        important: "important"
      }
    },
  };
</script>


<style lang="scss" scoped>
  $main_padding : 40px;
  $main_color : #451e3e;
  $secound_color : #F0F6F7FF;

  body {
    height: 1000px;
  }

  .about {
    padding: $main_padding;
    background-color: $main_color;
    color: $secound_color;

    h2 {
      padding: 20px 0;
    }

    h2:after {
      content: "";
      width: 30px;
      color: $secound_color;
      border: 2px solid $secound_color;
      display: block;
      margin: 7px auto;
    }
  }

  .situations {
    padding: $main_padding;
    background-color: $secound_color;

    h2 {
      padding: 20px 0;
    }

    h2:after {
      content: "";
      width: 30px;
      border: 2px solid $main_color;
      display: block;
      margin: 7px auto;
    }

    .ivu-card {
      margin-bottom: 40px;
      transition: all .5s ease-in-out;
    }

    .important {
      background-color: $main_color;
      color: #fff;

      .ivu-card-head p {
        color: #fff !important;
      }
    }

    .ivu-card:hover {
      box-shadow: 2px 1px 11px 1px $main_color;
    }
  }

  .blog {
    padding: $main_padding;
    background-color: $main_color;
    color: $secound_color;

    h2 {
      padding: 20px 0;
    }

    h2:after {
      content: "";
      width: 30px;
      color: $secound_color;
      border: 2px solid $secound_color;
      display: block;
      margin: 7px auto;
    }

    .ivu-card {
      color: $main_color;
      margin-bottom: 40px;
    }
  }

  .register {
    padding: $main_padding;
    background-color: $secound_color;
    color: $main_color;

    h2 {
      padding: 20px 0;
    }

    h2:after {
      content: "";
      width: 30px;
      color: $secound_color;
      border: 2px solid $main_color;
      display: block;
      margin: 7px auto;
    }

    .ivu-card h3 {
      padding: 15px 0;
    }
  }
</style>