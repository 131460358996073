<template>
    <div>
        <b-navbar toggleable="lg" type="dark" variant="dark">
            <div class="container">
                <b-navbar-brand href="#" right>بنك الدم</b-navbar-brand>

                <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

                <b-collapse id="nav-collapse" is-nav>
                    <b-navbar-nav>
                        <b-nav-item ><router-link to="/">الرئيسية</router-link></b-nav-item>
                        <b-nav-item href="#"><router-link to="/about">فكرتنا</router-link></b-nav-item>
                        <b-nav-item href="#"><router-link to="/cases">الحالات</router-link></b-nav-item>
                        <b-nav-item href="#"><router-link to="/blog">المدونة</router-link></b-nav-item>
                        <b-nav-item href="#"><router-link to="/donor">تسجيل متبرع</router-link></b-nav-item>
                        <!-- <b-nav-item-dropdown text="كن معنا" >
                            <b-dropdown-item href="#"><router-link to="/donor">تسجيل متبرع</router-link></b-dropdown-item>
                        </b-nav-item-dropdown> -->
                        <!-- <b-nav-item href="#">تواصل معنا</b-nav-item> -->
                    </b-navbar-nav>
                </b-collapse>
            </div>
        </b-navbar>
    </div>
</template>

<script>
    export default {
        name: 'Navbar',
        data() {
            return {

            }
        }
    }
</script>

<style lang="scss" scoped>
    $main_color : #451e3e;
    a {
        color : rgba(255, 255, 255, 0.5) !important
    }
    a.active {
        color : #fff !important
    }
    * {
        direction: rtl;
    }
    .bg-dark {
        background-color: $main_color !important;
    }
</style>