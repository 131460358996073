import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    meta : {
      title : "بنك الدم الالكتروني السوداني"
    }
  },
  {
    path: "/about",
    name: "About",
    component: () =>
      import("../views/About.vue"),
      meta : {
        title : "فكرتنا"
      }

  },
  {
    path: "/cases",
    name: "Cases",
    component: () =>
      import("../views/Cases.vue"),
      meta : {
        title : "الحالات"
      }
  },
  {
    path: "/blog",
    name: "Blog",
    component: () =>
      import("../views/Blog.vue"),
      meta : {
        title : "المدونة"
      }
  },
  {
    path: "/post",
    name: "Post",
    component: () =>
      import("../views/Post.vue"),
      meta : {
        title : "المدونة"
      }
  },
  {
    path: "/donor",
    name: "Donors",
    component: () =>
      import("../views/register/Donors.vue"),
      meta : {
        title : "تسجيل متبرع"
      }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
