<template>
    <div class="footer">
        <p>كل الحقوق محفوظة لبنك الدم الالكتروني</p>
    </div>
</template>

<script>
    export default {
        name: 'Foot',
        data() {
            return {
                
            }
        }
    }
</script>

<style lang="scss" >
    $secound_color : #F0F6F7FF;
    body {
        position: relative;
    }
    .footer {
        position: inherit;
        bottom:0;
        width: 100%;
        padding: 10px;
        background-color: #451e3e;
        color: $secound_color;
    }
</style>