<template>
    <hooper class="sliders">
        <slide class="slide-1"></slide>
        <slide class="slide-2"></slide>
        <slide class="slide-3"></slide>
        <slide class="slide-4"></slide>
    </hooper>
</template>

<script>
import { Hooper, Slide } from 'hooper';
import 'hooper/dist/hooper.css';

    export default {
        name: 'swiper-example-fade-effect',
        title: 'Fade effect',
        components: {
            Hooper,
            Slide
        },
        data() {
            return {
                swiperOption: {
                    spaceBetween: 30,
                    effect: 'fade',
                    pagination: {
                        el: '.swiper-pagination',
                        clickable: true
                    },
                    navigation: {
                        nextEl: '.swiper-button-next',
                        prevEl: '.swiper-button-prev'
                    }
                }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .swiper {
        height: 91vh;

        .swiper-slide {
            background-position: center;
            background-size: cover;

            &.slide-1 {
                background-image: url('../assets/background1.jpg')
            }

            &.slide-2 {
                background-image: url('../assets/background2.jpg')
            }

            &.slide-3 {
                background-image: url('../assets/background1.jpg')
            }

            &.slide-4 {
                background-image: url('../assets/background2.jpg')
            }

            &.slide-5 {
                background-image: url('../assets/background1.jpg')
            }
        }
    }

    @media (min-width: 992px) {
        .sliders {
            height: 91vh;
        }
    }
    


    .slide-1 {
        background-image: url('../assets/background1.jpg');
        background-size: cover;
    }

    .slide-2 {
        background-image: url('../assets/background2.jpg');
        background-size: cover;
    }

    .slide-3 {
        background-image: url('../assets/background3.jpg');
        background-size: cover;
    }

    .slide-4 {
        background-image: url('../assets/background1.jpg');
        background-size: cover;
    }
</style>