import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import VueRouter from 'vue-router'



// import Bootstrap pakeg
import { BootstrapVue } from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css' 
Vue.use(BootstrapVue)


// import awesom slider 
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/css/swiper.css'
Vue.use(VueAwesomeSwiper)

// import iviwe 
import ViewUI from 'view-design';
import 'view-design/dist/styles/iview.css';
Vue.use(ViewUI);


//use  vue
Vue.use(VueRouter);




Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
