<template>
  <div id="app">
    <div id="nav">
      <Navbar/>
    </div>
    <router-view />

    <div class="foot">
      <Foot/>
    </div>
  </div>
</template>

<script>
import Navbar from "@/components/NavbarComponent.vue";
import Foot from "@/components/FooterComponent.vue";

export default {
  components : {
    Navbar,
    Foot
  },
  watch: {
    '$route' (to) {
      document.title = to.meta.title || 'Your Website'
    }
  },
}
</script>

<style lang="scss">
  $secound_color : #F0F6F7FF;
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
</style>
